import Head from 'next/head';
import dynamic from 'next/dynamic';
import { GetServerSideProps} from 'next';
import { gsspWithNonce } from "@next-safe/middleware/dist/document";

import { GQLHandler, IHomeData } from '../lib/gqlHelper';
import { HandleMenuCookie } from '../lib/cookiesHelper';
import { IGrid } from '../components/common';

const Loader = dynamic(() => import('../components/Loader'), {
  loading: () => <p>....Loading.....</p>,
});

const MainImage = dynamic(() => import('../components/MainImage'), {
  loading: () => <Loader isLoading />,
});

const TitleBox = dynamic(() => import('../components/TitleBox'), {
  loading: () => <Loader isLoading />,
});

const GridContent =(grid:IGrid[]):JSX.Element =>{
  const Grid = dynamic(() => import('../components/Grid'), {
    loading: () => <Loader isLoading />,
  });

  return <Grid data={grid} />
};


const Home = (data: IHomeData,nonce: any) => {
  const homepage = data;
  return (
    <>
      <Head>
        <title>{homepage?.title}</title>
      </Head>
      <MainImage isHomepage={true} image={homepage?.mainImage[0]}>
        <TitleBox isHomepage={true} title={homepage?.title ?? ''} />
      </MainImage>

      {homepage?.grid && GridContent(homepage?.grid)}
    </>
  );
};
export default Home;

export const getServerSideProps: GetServerSideProps = gsspWithNonce(async (ctx) =>{
  await HandleMenuCookie(ctx)

  const res = await GQLHandler('home');
  const data = res?.entry as IHomeData
  return {props:data};
});
